import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = { 
  loader: false,
  fullScreen: false,
  userProfile: {},
  buyerUser: {},
  organization: {},
  showDialogMessage: false,
  dialogMessageTitle: null,
  dialogMessageText: null,
};
const store = new Vuex.Store({ state });

export default store;