import { AuthenticationService } from './../services/AuthenticationService'
const authenticationService = new AuthenticationService() 

export default [
  {
    name: 'APP_LOGOUT',
    callback: function () {
      authenticationService.logout()
      
      window.$cookies.remove("izcToken")
      this.$store.state.userProfile = {}
      this.$store.state.buyerUser = {}
      this.$store.state.organization = {}
      this.$router.replace({ name: 'Login' });
    }
  }
];
