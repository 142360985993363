<template>
  <v-app id="app">
    <Loader />
    <Dialog />
    
    <NavbarGuest v-if="renderNavBarGuest" />
    <Navbar v-if="renderNavBar" />
    <Sidebar v-if="renderSideBar" />

    <v-container v-if="noScroll">
      <router-view/>
    </v-container>
    <v-container fluid v-else>
      <v-main>
        <router-view/>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import Navbar from './../components/Navbar';
import NavbarGuest from './../components/NavbarGuest.vue'
import Sidebar from './../components/Sidebar';
import Loader from "@/components/Loader";
import Dialog from './../components/Dialog'

export default {
  components:  {
    Navbar,
    Sidebar,
    Loader,
    Dialog,
    NavbarGuest
  },
  data() {
    return {
      renderNavBar: false,
      renderNavBarGuest: false,
      renderSideBar: false,
      renderContainer: false,
      noScroll: false
    }
  },
  beforeUpdate() {
    if (this.$route && this.$route.meta) {
      this.renderNavBar = this.$route.meta.renderNavBar || false
      this.renderNavBarGuest = this.$route.meta.renderNavBarGuest || false
      this.renderSideBar = this.$route.meta.renderSideBar || false
      this.noScroll = this.$route.meta.noScroll || false
    }
  },
}
</script>