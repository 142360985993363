import axios from 'axios'

var API_URL = process.env.VUE_APP_BACKEND_URL

export class AuthenticationService {
  login (loginDto) {
    let url = `${API_URL}/auth/login`
    return axios.post(url, loginDto).then(response => response.data)
  }

  updatePassword(passwordRequest) {
    let url = `${API_URL}/auth/updatePassword`
    return axios.post(url, passwordRequest).then(response => response.data)
  }

  logout() {
    const url = `${API_URL}/auth/logout`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url).then(response => response.data)
  }

  forgotPassword(data) {
    const url = `${API_URL}/auth/resetPassword`
    return axios.post(url, data).then(response => response.data)
  }
}
