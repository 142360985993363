import axios from 'axios'
let Util = require('../../Util')

var API_URL = process.env.VUE_APP_BACKEND_URL

export class DataService {
  getDashboardData(projectId) {
    const url = `${API_URL}/dashboard/view`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, { projectId: projectId }).then(response => response.data)
  }

  getProjects(params) {
    const url = `${API_URL}/dashboard/findProjects`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, params).then(response => response.data)
  }

  saveComment(commentDto) {
    const url = `${API_URL}/dashboard/saveComment`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, commentDto).then(response => response.data)
  }

  loadComments(params) {
    const url = `${API_URL}/dashboard/getComments`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, params).then(response => response.data)
  }

  getSelectedProject(params) {
    const url = `${API_URL}/dashboard/getSelectedProject`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, params)
  }

  getQuote(projectSpecs) {
    const url = `${API_URL}/api/projectCost`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, projectSpecs).then(response => response.data)
  }

  getCreateProjectConfigs() {
    const url = `${API_URL}/api/getCreateProjectConfigs`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.get(url).then(response => response.data)
  }

  saveQuotation(data) {
    const url = `${API_URL}/api/saveQuotation`
    return axios.post(url, data, Util.appendHeaders()).then(response => response.data)
  }

  verifyUser(userDto) {
    const url = `${API_URL}/auth/verify`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, userDto)
  }

  loadProjectFiles(data) {
    const url = `${API_URL}/dashboard/loadProjectFiles`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(url, data).then(response => response.data)
  }

  auditLog(data) {
    return axios.post(`${API_URL}/audit/create`, data)
  }

  whoAmI(data) {
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    return axios.post(`${API_URL}/auth/whoAmI`, data)
  }

  joinOurCommunity(user) {
    const url = `${API_URL}/community/addUser`
    return axios.post(url, user).then(response => response.data)
  }

}