<template>
  <v-app-bar color="#333333" fixed dark app>
    <v-toolbar-side-icon @click.stop="handleDrawerToggle" v-if="renderSideBarToggle"></v-toolbar-side-icon>
    <v-toolbar-title class="ml-0 pl-3">
      <a @click="$router.push({ name: 'Root' })"><img src="./../assets/images/logo.png" class="logo"></a>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="checkPermission('CROSSTAB')"
      @click="$router.push({ name: 'CrossTab' })" text
    >
      Cross Tab
    </v-btn>
    <v-btn icon @click="handleFullScreen()">
      <v-icon>fullscreen</v-icon>
    </v-btn>

    <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-tranisition">
      <template v-slot:activator="{ on }">
        <v-avatar size="48" tile>
          <v-btn fab small dark v-on="on">
            <span class="white--text headline">{{ nameToInitials }}</span>
          </v-btn>
        </v-avatar>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in items"
          :to="!item.href ? { name: item.name } : item.href"
          :href="item.href"
          @click="item.click"
          ripple="ripple"
          rel="noopener"
          :key="index"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <img :src="this.$store.state.organization.logo_url" class="logo" v-if="this.$store.state.organization && this.$store.state.organization.logo_url">
  </v-app-bar>
</template>

<script>
export default {
  props: {
    renderSideBarToggle: Boolean,
    renderSearchBar: Boolean,
  },
  data() {
    return {
      items: [
        {
          icon: "account_circle",
          href: "/profile",
          title: "Profile",
          click: () => {}
        },
        {
          icon: "settings",
          href: "/changePassword",
          title: "Change Password",
          click: () => {}
        },
        {
          icon: "fullscreen_exit",
          href: "#",
          title: "Logout",
          click: () => {
            window.getApp.$emit("APP_LOGOUT");
          }
        }
      ]
    }
  },
  methods: {
    checkPermission(permissionToCheck) {
      let user = this.$store.state.buyerUser

      return (user && user.permissions && user.permissions.includes(permissionToCheck)) ? true : false
    },
    handleDrawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    handleFullScreen() {
      if (this.$store.state.fullScreen === false) {
        this.$store.state.fullScreen = !this.$store.state.fullScreen;
        this.openFullscreen();
      } else {
        this.$store.state.fullScreen = !this.$store.state.fullScreen;
        this.closeFullscreen();
      }
    },
    openFullscreen() {
      let elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  },
  computed: {
    nameToInitials() {
      try {
        let name = this.$store.state.userProfile.name
        return name.split(" ").map(x => x.charAt(0)).join("").substr(0, 2).toUpperCase();
      } catch (error) {
        return "IZ"
      }
    }
  }
};
</script>
