import Vue from 'vue'
import vuetify from './plugins/vuetify';
import App from './App.vue'
import router from './router'
import store from "./app.store.js";
import mixin from "./app.mixin.js";
import Default from './layouts/Default';
import axios from 'axios'
import VueCookies from 'vue-cookies'
import LoadScript from 'vue-plugin-load-script';
import { DataService } from './services/BuyerDataService' 

const dataServiceBuyer = new DataService();

Vue.use(LoadScript)
Vue.use(VueCookies)

VueCookies.config('7h')

Vue.component('default-layout', Default);
Vue.mixin(mixin);
Vue.config.productionTip = false

axios.interceptors.request.use(function (config) {
  if (Vue.config.devtools == false && !config.url.includes("/audit/create") && store.state.userProfile.name) {    
    dataServiceBuyer.auditLog({
      type: "request",
      method: config.method,
      url: config.url,
      data: config.data,
      doneBy: store.state.userProfile.name,
      doneAt: new Date(),
    })
  }
    
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(undefined, function (error) {
  store.state.loader = false

  if (error.response.status === 409 || error.response.status === 401) {
    if (error.response.status === 401) {
      store.state.dialogMessageTitle = "Session Expired"
      store.state.dialogMessageText = "Your session has expired. Please re-login to renew your session."
    } else if (error.response.status === 409) {
      store.state.dialogMessageTitle = "Duplicate Login Detected"
      store.state.dialogMessageText = "We have detected a duplicate login under the same account. You have been logged out automatically."
    }
    
    store.state.showDialogMessage = true
    window.$cookies.remove("izcToken")

    router.replace({ name: 'Login' });
  }

  if (error.response.status === 400) {
    store.state.dialogMessageTitle = null

    if (error.response.data && error.response.data.message) {
      store.state.dialogMessageText = error.response.data.message
    } else if (error.response.message) {
      store.state.dialogMessageText = error.response.message
    } else {
      store.state.dialogMessageText = "There is an error. Please reach out to our team for support."
    }

    store.state.showDialogMessage = true
  }

  return Promise.reject(error)
});

new Vue({
  vuetify,
  router,
  render: h => h(App),
  store,
}).$mount('#app')