import Vue from 'vue'
import Router from 'vue-router'
import paths from './paths'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from './../app.store'
import { DataService } from './../services/BuyerDataService' 

const dataService = new DataService();

// Ignore navigating to same path
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)

const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes: paths
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  if (to.matched.some(record => record.meta.guest)) {
    next()
  } else {
    if (window.$cookies.get('izcToken')  == null) {
      next({ name: 'Login' })
    } else {
      // Extend session
      window.$cookies.set('izcToken', window.$cookies.get('izcToken'))

      if (Object.keys(store.state.userProfile).length == 0 || Object.keys(store.state.buyerUser).length == 0) {
        store.state.loader = true

        dataService.whoAmI().then(res => { 
          store.state.organization = res.data.organization
          store.state.buyerUser = res.data.localUser
          store.state.userProfile = res.data.user
          store.state.loader = false
  
          next()
        }).catch(() => {
          window.location.reload()
        })
      } else {
        next()
      }
    }
  }
})

router.afterEach(() => {
  NProgress.done();
});

export default router;