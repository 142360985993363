const Menu =  [
  { header: 'Apps' },
  {
    title: 'Dashboard',
    icon: 'dashboard',
    name: 'Dashboard',
  },
  {
    title: 'DataTable',
    icon: 'dashboard',
    name: 'DataTable',
  },
  {
    title: 'Project',
    component: 'widgets',
    icon: 'widgets',
    items: [
      { name: 'Create Project', title: 'Create Project', component: 'CreateProject' },
      { name: 'Project Listing', title: 'Project Listing', component: 'ProjectListing' },
    ]
  },  
  { header: 'UI Elements' },
  {
    title: 'General',
    group: 'components',
    component: 'components',
    icon: 'tune',
    items: [ ]
  },
  { divider: true },
  { header: 'Extras' },
  {
    title: 'Pages',
    group: 'extra',
    icon: 'list',
    items: [
      { name: 'Login', title: 'Login', component: 'Login' },
      { name: '404', title: '404', component: 'NotFound' },
      { name: '403', title: '403', component: 'AccessDenied' },
      { name: '500', title: '500', component: 'ServerError' },
    ]
  },
];
// reorder menu
Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
});

export default Menu;
