export default [
  {
    path: '*',
    redirect: {
      path: '/404',
      meta: { layout: "default", guest: true, noScroll: true },
    }
  },  
  {
    path: '/404',
    name: 'NotFound',
    meta: { layout: "default", guest: true, noScroll: true },
    component: () => import('@/pages/NotFound.vue')
  },
  {
    path: '/403',
    name: 'AccessDenied',
    meta: { layout: "default", guest: true, noScroll: true },
    component: () => import('@/pages/Deny.vue')
  },
  {
    path: '/500',
    name: 'ServerError',
    meta: { layout: "default", guest: true, noScroll: true },
    component: () => import('@/pages/Error.vue')
  },
  {
    path: '/410',
    name: 'LinkExpired',
    meta: { layout: "default", guest: true, noScroll: true },
    component: () => import('@/pages/LinkExpired.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: "default", guest: true, noScroll: true },
    component: () => import('@/pages/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { layout: "default", renderNavBar: true },
    component: () => import('@/pages/Dashboard.vue')
  },
  {
    path: '/pricing/calculator',
    name: 'CreateProject',
    meta: { layout: "default", guest: true },
    component: () => import('@/pages/CreateProject.vue')
  },
  {
    path: '/project/calculator',
    name: 'ProjectCalculator',
    meta: { layout: "default" },
    component: () => import('@/pages/CreateProject.vue')
  },
  {
    path: '/project/listing',
    name: 'ProjectListing',
    meta: { layout: "default" },
    component: () => import('@/pages/ProjectListing.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { layout: "default", renderNavBar: true },
    component: () => import('@/pages/Profile.vue')
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    meta: { layout: "default", renderNavBar: true },
    component: () => import('@/pages/ChangePassword.vue')
  },
  {  
    path: '/',
    meta: { layout: "default" },
    name: 'Root',
    redirect: {
      name: 'Dashboard'
    }
  },
  {
    path: '/crossTab',
    name: 'CrossTab',
    meta: { layout: "default", renderNavBar: true },
    component: () => import('@/pages/CrossTab.vue')
  },
  // {
  //   path: '/dataTables',
  //   name: 'DataTable',
  //   meta: { layout: "default", renderNavBar: true },
  //   component: () => import('@/pages/DataTable.vue')
  // },
  {
    path: '/census/registration',
    name: 'CensusRegistration',
    meta: { layout: "default", guest: true, noScroll: true},
    component: () => import('@/pages/Registration.vue')
  },

  {
    path: '/census/setPassword/', // change to query
    name: 'CensusSetPassword',
    meta: { layout: "default", guest: true, noScroll: true, renderNavBarGuest: true  },
    component: () => import('@/pages/SetPassword.vue')
  },

  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    meta: { layout: "default", guest: true, noScroll: true, renderNavBarGuest: true },
    component: () => import('@/pages/ForgotPassword.vue')
  },
  {
    path: '/join-our-community',
    name: 'Community',
    meta: { layout: "default", guest: true, noScroll: true },
    component: () => import('@/pages/JoinOurCommunity.vue')
  },
  {
    path: '/mobileapp-resetpassword',
    name: 'MobileAppResetPassword',
    meta: { layout: "default", guest: true, noScroll: true, renderNavBarGuest: true  },
    component: () => import('@/pages/ResetPassword.vue')
  }
];
